import { template as template_5ec0292329a04dee9ab64a99bb3bb012 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5ec0292329a04dee9ab64a99bb3bb012(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
