import { template as template_a3eef79178924a6384386175e45d5c3c } from "@ember/template-compiler";
const WelcomeHeader = template_a3eef79178924a6384386175e45d5c3c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
