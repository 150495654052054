import { template as template_cb4e26087c834a56b4b7463b0c33f881 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_cb4e26087c834a56b4b7463b0c33f881(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
